@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Catchy Melody', sans-serif;
    overflow-x: hidden;
}

@layer components {
    h1 {
        @apply text-[64px] font-normal uppercase
    }

    h2 {
        @apply xl:text-[56px] lg:text-5xl md:text-4xl sm:text-3xl font-normal uppercase
    }

    h3 {
        @apply xl:text-[44px] lg:leading-normal sm:text-3xl text-2xl font-normal uppercase
    }

    h4 {
        @apply text-[28px] font-bold font-['Mulish']
    }

    h5 {
        @apply text-2xl font-bold font-['Mulish']
    }

    h6 {
        @apply lg:text-xl font-normal
    }

    .title1 {
        @apply text-[18px] font-bold font-['Mulish']
    }

    .title2 {
        @apply lg:text-base text-xs font-bold font-['Mulish']
    }

    .title3 {
        @apply text-sm font-bold font-['Mulish']
    }

    .title4 {
        @apply text-xs font-bold font-['Mulish']
    }

    .title5 {
        @apply text-sm font-normal
    }

    .body1 {
        @apply text-[18px] font-normal font-['Mulish']
    }

    .body2 {
        @apply lg:text-base sm:text-sm text-xs font-normal font-['Mulish']
    }

    .body3 {
        @apply text-sm font-normal font-['Mulish']
    }

    .body4 {
        @apply text-xs font-normal font-['Mulish']
    }

    .inner-container {
        @apply max-w-[1296px] mx-auto px-4
    }

    .line {
        @apply max-w-48 w-full h-px bg-[#B3B3B3]
    }

    .cont {
        @apply max-w-[1440px] mx-auto
    }

    .hero-card {
        @apply mt-3 rounded-20 border border-dashed mb-9 shadow-hero border-orange md:max-w-80 max-w-450 lg:max-w-450 py-4 px-6 bg-lightGray grid grid-cols-2 lg:gap-8 gap-2 justify-between
    }

    .number-btn {
        @apply lg:h-16 lg:w-16 h-10 w-10 flex items-center justify-center bg-orange rounded-full text-xl lg:text-4xl text-white font-sans
    }

    .yellow-card {
        @apply rounded-20 shadow-yellow border border-dashed border-orange lg:p-7 p-4
    }

    .grey-card {
        @apply rounded-20 border border-dashed border-dark shadow-grey lg:p-7 p-4
    }

    .black-card {
        @apply rounded-20 border border-dashed py-5 sm:px-6 px-3 shadow-dark border-dark bg-white
    }
}

.font-balo {
    font-family: "Baloo 2", sans-serif;
}

.blur {
    background: rgba(36, 36, 36, 0.15);
    backdrop-filter: blur(6px);
}

*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}